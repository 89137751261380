import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.article`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  .extra-wrapper {
    padding: 0 3rem 3rem 3rem;
  }
`

const Discounts = () => (
  <Wrapper>
    <div className="extra-wrapper">
      <h1>Скидки</h1>
      <p>И так, какие скидки мы предлагаем этим летом:</p>
      <ul>
        <li>❗с 1 по 13 июня скидка 40% на все дни проживания;</li>
        <li>❗с 14 по 25 июня скидка 25% на проживание в будние дни.</li>
      </ul>
      <p>И не забываем, что питание (завтрак, обед) включено в стоимость.</p>
    </div>
  </Wrapper>
)

export default Discounts
